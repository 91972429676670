import React, {useState, useRef} from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import Vimeo from '@u-wave/react-vimeo';
import Slider from 'react-slick';
import Icon from '../Icons/Icon';

const container = {
    hidden: { 
        opacity: 0,
        transition: {
            duration: 0.3
        }
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.3
        }
    },
    exit: { 
        opacity: 0
    }
};

const arrow = {
    right: {
        x: [0, -5]
    },
    left: {
        x: [0, 5]
    }
}

function NextArrow(props) {
    const { onClick } = props;
    return (
        <motion.button
            className="carousel-chevron"
            onClick={onClick}
            type="button"
            variants={arrow}
            whileTap="left"
            >
            <Icon name="nextchevron" />
        </motion.button>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <motion.button
            className="carousel-chevron"
            onClick={onClick}
            type="button"
            variants={arrow}
            whileTap="right"
            >
            <Icon name="prevchevron" />
        </motion.button>
    );
}

function Grid(props) {

    const settings = {
        background: true,
        loop: true,
        responsive: true,
        muted: false,
        pip: false,
        controls: false
    }

    return (
        <motion.div 
            className='viewer-grid'
            >
            {props.slides.map((slide, id) => (
                <motion.div
                    className="slide"
                    type="button"
                    onClick={() => {
                        props.handleSelect([id])
                        props.handleGrid(!props.isGrid)
                    }}
                    id={slide.sys.id} 
                    key={slide.sys.id}
                    >
                    {slide.imagesCollection.items.map((image, index) => (
                        <img 
                            src={image.url_small} 
                            alt={image.title}
                            key={index}
                            />
                    ))}
                    {slide.video === null ? (null):(
                        <>
                            <div className="grid-plyr-btn" />
                            <Vimeo {...settings}
                                className= "grid-plyr"
                                video= {slide.video.previewVideoId}
                                width= "100%"
                                height= "100%"
                                quality= "144p"
                                />
                        </>
                    )}
                </motion.div>
            ))}
        </motion.div>
    )
}

const ViewerImage = (props) => {
    const [isFullscreen, setFullscreen] = useState(false);
    const [fullscreenIndex, setFullscreenIndex] = useState(null);

    const sliderRef = useRef();

    const settings = {
        infinite: true,
        lazyLoad: "progressive",
        fade: true,
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        autoplay: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        beforeChange: (current, next) => props.index(next)
    };

    const handleSelect = (index) => {
        sliderRef.current.slickGoTo(index);
    };

    const handleGrid = () => {
        props.setGrid(props.isGrid);
    }

    const handleFullscreen = (index) => {
        setFullscreen(!isFullscreen);
        setFullscreenIndex(index);
    }

    return (
        <div className='viewer-image'>
            <AnimatePresence>
                {isFullscreen && (
                    <motion.div className="fullscreen"
                        key={fullscreenIndex}
                        initial={{ 
                            scale: 1.5,
                            opacity: 0
                        }}
                        animate={{ 
                            scale: 1,
                            opacity: 1
                        }}
                        exit={{
                            scale: 1.5,
                            opacity: 0
                        }}
                        transition={{
                            type: 'tween',
                            ease: 'anticipate',
                            duration: 0.5
                        }}
                    >
                        <motion.button 
                            className="close-btn"
                            initial={{ rotate: 0 }}
                            animate={{ rotate: 90 }}
                            whileHover={{ rotateZ: 90 }}
                            exit={{ rotate: -90 }}
                            transition={{ duration: 0.5 }}
                            type="button"
                            onClick= {() => {handleFullscreen()}}>
                                <span/>
                                <span/>
                        </motion.button>
                        <img 
                            className="fullscreen-img" 
                            src={props.slides[props.slideIndex].imagesCollection.items[fullscreenIndex].url_large} 
                            alt={props.slides[props.slideIndex].imagesCollection.items[fullscreenIndex].title}
                            onClick= {() => {handleFullscreen()}}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {props.grid && (
                    <Grid slides={props.slides} handleSelect={handleSelect} handleGrid={handleGrid}/>
                )}
            </AnimatePresence>
            <Slider {...settings} ref={sliderRef}>
                {props.slides.map((slide) => (
                    <div 
                        className="slide" 
                        id={slide.sys.id} 
                        key={slide.sys.id}
                        >
                        {slide.video === null ? (
                            <div
                                className='slide-images'>
                                    {slide.imagesCollection.items.map((img, id) => (
                                        <img
                                            className='slide-image'
                                            src={img.url_large}
                                            alt= {img.title}
                                            key={id}
                                            onClick= {() => {handleFullscreen([id])}}
                                            />
                                    ))}
                            </div>
                        ):(
                            <>
                                <Vimeo
                                    className= "slide-playr"
                                    video= {slide.video.mainVideoId}
                                    width= "100%"
                                    height= "100%"
                                    muted= {false}
                                    showTitle= {false}
                                    playsInline= {true}
                                    controls= {true}
                                />
                            </>
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default ViewerImage