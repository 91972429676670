import React from 'react';
import { motion } from 'framer-motion';

const textAnim = {
    hidden: { 
        y: -30,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5
        }
    }
};

const PageTitle = (props) => {
    return (
        <motion.h1 
            className='pagetitle'
            variants={textAnim}
            initial="hidden"
            animate="visible"
            >
            {props.title}
        </motion.h1>
    )
}

export default PageTitle;