import React from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';
import useContentful from "../hooks/useContentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Logo from "../Logo";
import PageTitle from './PageTitle';

const query= `
{
    aboutPageCollection(limit: 10) {
        items {
            title
            bio {
                json
            }
            clients
            contact {
                json
            }
            portraitCollection {
                items {
                    title
                    url(transform: { quality: 50 })
                }
            }
        }
    }
}`

const About = () => {
    let { data } = useContentful(query);

    if (!data) return <div className="page-loader"><Logo /></div>;

    const aboutPage = data.aboutPageCollection.items[0];

    return (
        <motion.div 
            className="about"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
        >
            <Helmet>
                <title>Mat+Kat | {aboutPage.title}</title>
            </Helmet>
            <PageTitle title={aboutPage.title}/>
            <div className="self-portraits">
                {aboutPage.portraitCollection.items.map((img, id) => (
                    <img
                        className='self-portrait'
                        src={img.url}
                        alt= {img.title}
                        key={id}
                        />
                ))}
            </div>
            <div className="bio">
                {documentToReactComponents(aboutPage.bio.json)}
            </div>
            <div className='client-contact'>
                <div className="contact">
                    <h2>Contact</h2>
                    <div className="contact-contents">
                        {documentToReactComponents(aboutPage.contact.json)}
                    </div>
                </div>
                <div className="clients">
                    <h2>Select Clients</h2>
                    <div className="client-list">
                        {aboutPage.clients.map((client, id) => (
                            <p className="client" key={id}>{client}</p>
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default About;