import { useState } from 'react';
import useContentful from "../hooks/useContentful";
import { Helmet } from "react-helmet";
import Vimeo from '@u-wave/react-vimeo';
import { AnimatePresence, motion } from 'framer-motion';
import Logo from '../Logo';
import { useParams } from "react-router-dom";

const project = {
    initial: {
        y: "-50%",
        opacity: 0,
        transition: {
            duration: 0.3
        }
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            duration: 0.5
        }
    },
    exit: {
        scale: 1.2,
        opacity: 0,
        transition: {
            duration: 0.2
        }
    }
}

const videoPlayr = {
    intial: {
        opacity: 0,
        transition: {
            delay: 0.5,
            duration: 0.3
        }
    },
    hover: {
        scale: [1, 0.9, 1],
        transition: {
            duration: 0.3
        }
    },
    enter: {
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 0.3
        }
    }
}

const spinner = {
    spin: {
        rotate: 360
    },
    transition: {
        duration: 2, 
        repeat: Infinity
    }
}

export default function MotionProject({ props }) {
    const [infoOpen, setInfoOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const { projectName } = useParams();

    const query = `{
        motionItemCollection (where:{slug: "${projectName}"} limit: 1) {
            items {
                    title
                    mainVideoId
                    otherVideoId
                    previewVideoId
                    slug
                    client
                    director
                    cinematographer
                    cd
                    editor
                    color
                    soundDesign
                    stylist
                    hair
                    makeUp
                    manicurist
                    groomer
                    setDesigner
                    producer
                }
            }
        }
    `;

    let { data } = useContentful(query);

    if (!data) return <div className="motion page-loader"><Logo /></div>;

    const motionItem = data.motionItemCollection.items[0];

    const handleInfo = () => {
        setInfoOpen(!infoOpen);
    }

    let infoBtn = [
        'info-caret',
        infoOpen ? ' active' : ''
    ]
    infoBtn = infoBtn.join('')

return(
    <>
        <Helmet>
            {!motionItem.client ? (
                <title>{motionItem.title}</title>
            ) : (
                <title>{motionItem.client} | {motionItem.title}</title>
            )}
        </Helmet>
        <motion.div 
            as="div" 
            className="motion-project" 
            id={motionItem.itemId}
            variants={project}
            initial="initial"
            animate="animate"
            exit="exit"
            >
            <div className="motion-project-title">
                <h1>{motionItem.title}</h1>
            </div>
            <div
                className="contents"
                >
                <Vimeo
                    className= "motion-project-playr"
                    video= {video === null ? (motionItem.mainVideoId) : (video)}
                    width= "100%"
                    height= "100%"
                    muted= {false}
                    showTitle= {false}
                    playsInline= {true}
                    controls= {true}
                />
                <motion.div
                    className='video-spinner'
                    variants={project}
                    initial="initial"
                    animate="animate"
                    >
                        <motion.div
                            className='logo-container'
                            variants={spinner}
                            animate="spin"
                            transition={{ ease: "linear", duration: 2, repeat: Infinity}}
                            >
                            <Logo />
                        </motion.div>
                </motion.div>
            </div>
            <button
                className="info-button"
                onClick={handleInfo}
                type= "button"
            >
                <h1>info</h1>
                <motion.div
                    className={infoBtn}
                >
                    <span/>
                    <span/>
                </motion.div>
            </button>
            <AnimatePresence>
                {infoOpen && (
                <motion.div
                    key="info"
                    className='motion-project-info'
                    initial= {{
                        y: 500
                    }}
                    animate= {{
                        y: 0,
                        transition: {
                            duration: 0.3
                        }
                    }}
                    exit= {{
                        y: 500,
                        transition: {
                            duration: 0.6
                        }
                    }}
                >
                    {motionItem.client === null ? (null) : (
                        <div className="client" id="info-text">
                            <h4>client</h4>
                            <h3>{motionItem.client}</h3>
                        </div>
                    )}
                    <div className='credits' id="info-text">
                        <div className='credits-title'>
                            <h4>credits</h4>
                        </div>
                        <div className="credits-text">
                            {((motionItem.director === motionItem.cinematographer) ? (<p>director + dp: <b>{motionItem.director}</b></p>) : (<p>director: <b>{motionItem.director}</b></p>)) && ((motionItem.director === motionItem.editor && motionItem.editor !== motionItem.cinematographer) ? (<p>director + editor: <b>{motionItem.director}</b></p>) : (<p>director: <b>{motionItem.director}</b></p>))}
                            {(motionItem.cinematographer === motionItem.editor) ? (<p>dp + editor: <b>{motionItem.cinematographer}</b></p>):(<p>dp: <b>{motionItem.cinematographer}</b></p>)}
                            {motionItem.cd === null ? (false) : (<p>creative director: <b>{motionItem.cd}</b></p>)}
                            {((motionItem.editor !== motionItem.cinematographer) && (motionItem.editor === motionItem.director)) ? (false) : (<p>editor: <b>{motionItem.editor}</b></p>) && ((motionItem.editor === motionItem.cinematographer) ? (false) : (<p>editor: <b>{motionItem.editor}</b></p>))}
                            {motionItem.color === null ? (false) : (<p>color: <b>{motionItem.color}</b></p>)}
                            {motionItem.soundDesign === null ? (false) : (<p>sound: <b>{motionItem.soundDesign}</b></p>)}
                            {motionItem.stylist === null ? (false) : (<p>stylist: <b>{motionItem.stylist}</b></p>)}
                            {motionItem.hair === null ? (false) : (<p>hair: <b>{motionItem.hair}</b></p>)}
                            {motionItem.makeUp === null ? (false) : (<p>make-up: <b>{motionItem.makeUp}</b></p>)}
                            {motionItem.manicurist === null ? (false) : (<p>manicurist: <b>{motionItem.manicurist}</b></p>)}
                            {motionItem.groomer === null ? (false) : (<p>groomer: <b>{motionItem.groomer}</b></p>)}
                            {motionItem.setDesigner === null ? (false) : (<p>set designer: <b>{motionItem.setDesigner}</b></p>)}
                            {motionItem.producer === null ? (false) : (<p>production: <b>{motionItem.producer}</b></p>)}
                        </div>
                    </div>
                    {motionItem.otherVideoId && <div className="other-videos">
                        <h4 id="info-text">More from this project:</h4>
                        <div className="videos">
                            {motionItem.otherVideoId.map((vid, i) => (
                                <motion.div 
                                    className="video-holder" 
                                    key={i}
                                    variants={videoPlayr}
                                    initial="intial"
                                    animate="enter"
                                    whileTap="hover"
                                    onClick= {() => {setVideo(video === vid ? (motionItem.mainVideoId) : (vid))}}
                                    >
                                    <button
                                        />
                                    <Vimeo
                                        className= "other-videos-playr"
                                        video= {video === vid ? (motionItem.mainVideoId) : (vid)}
                                        muted= {true}
                                        autoplay= {false}
                                        controls= {false}
                                        />
                                </motion.div>
                            ))}
                        </div>
                    </div>}
                </motion.div>)}
            </AnimatePresence>
        </motion.div>
    </>
    );
}